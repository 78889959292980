<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <XyzTransition
        appear
        xyz="fade down-3 delay-6"
      >
        <v-card
          class="pa-4 d-flex flex-column auth-card"
          width="400"
        >
          <XyzTransition
            appear
            xyz="fade left-3 delay-3"
          >
            <router-link
              to="/"
            >
              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                max-width="130px"
                alt="logo"
                contain
                eager
                class="app-logo my-2 mx-auto"
              />
            </router-link>
          </XyzTransition>
          <v-tabs-items
            v-if="!loadingInvitationPage"
            v-model="currentStep"
            class="pa-0 ma-0"
          >
            <v-tab-item>
              <div v-if="currentStep === 0">
                <v-img
                  class="ma-4 mt-8"
                  src="@/assets/images/3d-characters/illustration-flora.png"
                />
                <span class="mt-4 d-block text-center">Kamu diundang untuk bergabung di workspace {{ workspaceData ? workspaceData.name : '' }}.</span>
                <v-img
                  contain
                  class="ma-4 mx-auto rounded-lg"
                  width="100"
                  height="100"
                  :src="workspaceData ? workspaceData.photo : null"
                />
                <v-btn
                  block
                  color="primary"
                  class="mt-4"
                  :loading="loading"
                  @click="joinWorkspace"
                >
                  Gabung Sekarang
                </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div
                v-if="currentStep === 1"
                class="d-flex flex-column align-center"
              >
                <v-img
                  contain
                  class="ma-4 mt-8"
                  src="@/assets/images/3d-characters/illustration-john.png"
                />
                <span class="text-center text-subtitle-1 mb-2">
                  Berhasil bergabung ke workspace!
                </span>
                <v-btn
                  block
                  color="primary"
                  class="mt-4"
                  @click="redirect"
                >
                  Arahkan Saya ke Dashboard
                </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div
                v-if="currentStep === 2"
                class="d-flex flex-column align-center"
              >
                <v-img
                  contain
                  class="ma-4 mt-8"
                  src="@/assets/images/3d-characters/error.png"
                />
                <span class="text-center text-subtitle-1 mb-2">
                  Gagal bergabung ke workspace!
                </span>
                <span class="text-center text-caption">
                  {{ errorMessage }}
                </span>
                <v-btn
                  block
                  color="primary"
                  class="mt-4"
                  @click="redirect"
                >
                  Kembali ke Dashboard
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
            class="mx-auto my-12"
          />
        </v-card>
      </XyzTransition>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <XyzTransition
      appear
      xyz="fade left-3"
    >
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      />
    </XyzTransition>

    <!-- tree  -->
    <XyzTransition
      appear
      xyz="fade right-3 delay-3"
    >
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      />
    </XyzTransition>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import useWorkspace from '@/composables/useWorkspace'
import router from '@/router'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      getInvitationPage, loadingInvitationPage, workspaceData,
      joinToWorkspace, loadingJoinWorkspace,
    } = useWorkspace()
    // 0: initial, 1: success, 2: failed
    const currentStep = ref(0)
    const errorMessage = ref('')

    const redirect = () => {
      window.location.replace('/')
    }
    const joinWorkspace = async () => {
      joinToWorkspace(props.id).then(() => {
        currentStep.value = 1
      }).catch(err => {
        errorMessage.value = err.message
        currentStep.value = 2
      })
    }

    onMounted(() => {
      getInvitationPage(props.id).catch(() => {
        router.replace({ name: 'workspace-root' })
      })
    })

    return {
      currentStep,

      redirect,

      joinWorkspace,

      workspaceData,

      loadingInvitationPage,
      loadingJoinWorkspace,
      errorMessage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
